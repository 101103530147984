import "./AboutMe.css";

function AboutMe() {
  return (
    <div className="section">
      <div className="about-me-text">
        <div className="about-me">
          Hello, my name is <span className="name">Lauren Koch</span> and I'm a
          fullstack software developer. I have experience with Typescript,
          React, Node, React Native, and Apollo GraphQL.
        </div>
        <div>Check out some of my work below!</div>
      </div>
    </div>
  );
}

export default AboutMe;
