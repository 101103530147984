import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "./Contact.css";

function Contact() {
  return (
    <div className="section">
      <div className="link-container">
        <a
          className="link contact-link"
          href="https://github.com/laurenkch"
          target="blank"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a
          className="link contact-link"
          target="blank"
          href="https://www.linkedin.com/in/laurenkch/"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          className="link contact-link"
          target="blank"
          href="mailto:laurenkochdev@gmail.com"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
    </div>
  );
}

export default Contact;
